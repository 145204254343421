import React, { useState } from "react";
import "./App.css";

const PayFastForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    cellphoneNumber: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Submit PayFast form directly without email sending
    document.getElementById("payfast-form").submit();
  };

  return (
    <div>
      <div className="div">
        <h1>Registration Form</h1>
      </div>
      <form
        id="payfast-form" // Give the form an ID for submission later
        name="PayFastPayNowForm"
        action="https://payment.payfast.io/eng/process"
        method="post"
        className="form-container"
        onSubmit={handleSubmit}
      >
        <h2>Personal Information</h2>
        <div>
          <label>First Name *</label>
          <input
            required
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Last Name *</label>
          <input
            required
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Email Address *</label>
          <input
            required
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Cellphone Number *</label>
          <input
            required
            type="tel"
            name="cellphoneNumber"
            value={formData.cellphoneNumber}
            onChange={handleChange}
          />
        </div>

        {/* Custom fields for PayFast */}
        <input required type="hidden" name="cmd" value="_paynow" />
        <input
          required
          type="hidden"
          name="receiver"
          pattern="[0-9]"
          value="11718503"
        />
        <input required type="hidden" name="amount" value="550.00" />
        <input
          required
          type="hidden"
          name="item_name"
          maxLength="255"
          value="CyberSecurity"
        />
        <input
          type="hidden"
          name="custom_str1" // Custom field for First Name
          value={formData.firstName}
        />
        <input
          type="hidden"
          name="custom_str2" // Custom field for Last Name
          value={formData.lastName}
        />
        <input
          type="hidden"
          name="custom_str3" // Custom field for Email
          value={formData.email}
        />
        <input
          type="hidden"
          name="custom_str4" // Custom field for Cellphone Number
          value={formData.cellphoneNumber}
        />

        <div className="submit-button-container">
          <input
            type="image"
            src="https://my.payfast.io/images/buttons/PayNow/Primary-Large-PayNow.png"
            alt="Pay Now"
            title="Pay Now with PayFast"
          />
        </div>
      </form>
    </div>
  );
};

export default PayFastForm;